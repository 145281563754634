<template>
  <nav
    class="bg-teal-900 px-2 sm:px-4 py-2.5 fixed w-full z-20 top-0 left-0 border-b border-teal-200"
  >
    <div
      class="container flex flex-wrap items-center justify-between mx-auto max-w-7xl"
    >
      <NavContact />
      <div class="flex md:order-2">
        <router-link to="/iscejo-dom"><Button text="Iščejo dom" /></router-link>
        <button
          data-cy="navburger-test"
          data-collapse-toggle="navbar-sticky"
          type="button"
          class="inline-flex items-center p-2 text-sm text-teal-500 rounded-lg md:hiddenfocus:outline-none xl:hidden"
          aria-controls="navbar-sticky"
          aria-expanded="false"
          @click="showMenu()"
        >
          <span class="sr-only">Open main menu</span>
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <!-- HAMBURGER LINKS -->
        <ul
          :class="this.showMobileMenu ? 'open-menu' : 'closed-menu'"
          class="animation mt-14 pt-4 pb-4 w-screen h-auto px-0 mx-auto absolute right-0 bg-teal-900 opacity-95 max-w-7xl"
        >
          <li
            v-for="navigation in navigations"
            :key="navigation"
            class="text-sm mt-1 text-white text-bold rounded-sm items-center bg-teal-800 px-10 py-3 w-10/12 hover:opacity-80 mx-auto text-center xl:text-2xl uppercase animation"
            @click="showMenu()"
          >
            <router-link
              :to="navigation.link"
              :data-testid="navigation.testid"
              class="hover:font-semibold text-center mx-auto"
              @click="scrollBehavior"
            >
              {{ navigation.text }}</router-link
            >
          </li>
        </ul>
      </div>
      <div
        class="items-center justify-between hidden w-full md:w-auto md:order-1 xl:flex"
      >
        <!-- DESKTOP LINKS -->
        <ul
          class="flex flex-col p-4 mt-4 border rounded-lg bg-teal-900 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0"
        >
          <li
            v-for="navigation in navigations"
            :key="navigation"
            class="block py-2 pl-3 pr-4 text-white bg-teal-900 rounded md:bg-transparent md:p-0 capitalize"
            aria-current="page"
          >
            <router-link
              :to="navigation.link"
              :data-cy="navigation.testid"
              class="hover:font-semibold text-center mx-auto text-md lg:text-lg"
              @click="scrollBehavior"
            >
              {{ navigation.text }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import Button from "../SharedComponents/Button.vue";
import NavContact from "./NavContact.vue";

export default {
  name: "Navigation",
  components: {
    Button,
    NavContact,
  },
  data() {
    return {
      navigations: [
        { link: "/", text: "domov", testid: "domov-test" },
        { link: "/O-nas", text: "o nas", testid: "onas-test" },
        { link: "/iscejo-dom", text: "iščejo dom", testid: "iscejodom-test" },
        {
          link: "/problematika",
          text: "problematika pointerjev",
          testid: "problematika-test",
        },
        { link: "/O-pasmah", text: "o pasmah", testid: "opasmah-test" },
        { link: "/Posvojitve", text: "posvojitve", testid: "posvojitve-test" },
        { link: "/Kontakt", text: "kontakt", testid: "kontakt-test" },
      ],
      showMobileMenu: false,
    };
  },
  methods: {
    showMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    scrollBehavior() {
      document.getElementById("app").scrollIntoView();
    },
  },
};
</script>
<style scoped>
.open-menu {
  opacity: 1;
}
.closed-menu {
  opacity: 0;
  height: 0;
  padding: 0;
  visibility: hidden;
}
.animation {
  @apply transition ease-in-out duration-500;
}
</style>
