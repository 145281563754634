<template>
  <div class="flex flex-col mt-4 lg:-mt-20 lg:p-6 mb-4 lg:mb-0">
    <h2
      class="text-medium lg:text-xl font-semibold mb-2"
      data-test="header-test"
    >
      Kontakt
    </h2>
    <div
      v-for="text in texts"
      :key="text"
      class="container"
      data-cy="kontakt-test-footer"
    >
      <img :src="text.img" alt="" />
      <p>{{ text.text }}</p>
    </div>
  </div>
</template>
<script>
import Location from "@/images/location.png";
import Phone from "@/images/phone-call.png";
import Mail from "@/images/email.png";
export default {
  name: "Kontakt",
  data() {
    return {
      texts: [
        { img: Location, text: "Ulica Tuga Vidmarja 3, 4000 Kranj" },
        { img: Phone, text: "+386 31 507 305" },
        { img: Mail, text: "pomocpointerjem@gmail.com" },
      ],
    };
  },
};
</script>

<style scoped>
p {
  @apply text-sm xl:text-2xl  ml-2;
}
.container {
  @apply flex flex-row mt-2 items-center;
}
img {
  @apply w-5 h-5;
}
</style>
