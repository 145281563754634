<template>
  <button :class="buttonClass">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: "primary",
      validator(value) {
        return ["primary", "secondary"].includes(value);
      },
    },
  },
  computed: {
    buttonClass() {
      return {
        [this.type]: true,
      };
    },
  },
};
</script>
<style scoped>
button {
  @apply xl:text-xl font-semibold rounded-full uppercase px-7 py-3;
}
.primary {
  @apply bg-teal-700 hover:opacity-90 text-zinc-100 ml-3  hover:border-2;
}
.secondary {
  @apply bg-zinc-100 text-teal-700 border-solid border-2 border-teal-700 py-2 mt-8 mx-auto hover:text-zinc-100 hover:bg-teal-700 mb-4 w-full;
}
</style>
