<template>
  <div v-if="isOpen" class="cookie" :class="containerPosition">
    <div class="cookie_content" data-test="cookie-text-test">
      <slot name="message">
        Ta spletna stran operira s pomočjo piškotkov, ki so namenjeni temu, da
        izboljšajo in poenostavijo tvoje brskanje po spletni strani. Piškotke
        uporabljamo za štetje obiskovalcev. Več o piškotkih.
      </slot>
    </div>
    <div
      class="cookie_button accept"
      data-cy="accept-button-test"
      @click="accept"
    >
      Sprejmi
    </div>
    <div class="cookie_button deny" data-cy="deny-button-test" @click="deny">
      Zavrni
    </div>
  </div>
</template>
<script>
export default {
  name: "Cookies",
  props: {
    position: {
      type: String,
      default: "bottom",
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    containerPosition() {
      return `cookie--${this.position}`;
    },
  },
  created() {
    if (!this.getGDRP() === true) {
      this.isOpen = true;
    }
  },
  methods: {
    getGDRP() {
      return localStorage.getItem("GDPR:accepted", true);
    },
    accept() {
      this.isOpen = false;
      localStorage.setItem("GDPR:accepted", true);
    },
    deny() {
      this.isOpen = false;
      localStorage.setItem("GDPR:accepted", false);
    },
  },
};
</script>
<style scoped>
.cookie {
  position: fixed;
  z-index: 100;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 12px;
  padding: 10px;
  background: #115e59;
  bottom: 0;
}
.cookie_content {
  grid-column: auto / span 5;
  padding: 5px;
  text-align: left;
  display: flex;
  align-items: center;
}
.cookie_button {
  margin: 16px 0;
  grid-column: auto / span 1;
  text-align: center;
  padding: 5px;
  color: #115e59;
  cursor: pointer;
  border: 2px solid #d4d4d8;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cookie_button.accept {
  padding: 5px;
  background: #d4d4d8;
}
.cookie_button:hover {
  opacity: 0.8;
}
.cookie_button.deny {
  padding: 5px;
  color: #d4d4d8;
  background: #115e59;
  cursor: pointer;
}
</style>
