<template>
  <div class="mt-4 lg:p-4">
    <h2 class="text-medium lg:text-xl xl:text-3xl font-semibold mb-2">
      Informacije
    </h2>
    <ul v-for="data in datas" :key="data" class="flex-col flex">
      <li
        class="flex flex-row items-center content-center mt-4 text-sm xl:text-2xl hover:underline hover:text-teal-500"
      >
        <img
          src="@/images/pawprint.png"
          alt="-"
          class="w-5 h-auto lg:w-7 mr-2"
        />
        <router-link
          :to="data.url"
          @click="scrollBehavior"
          :data-cy="data.test"
          >{{ data.text }}</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "InformacijeFooter",
  data() {
    return {
      datas: [
        { url: "/O-nas", text: "O nas", test: "onas-footer" },
        { url: "/Iscejo-dom", text: "Iščejo dom", test: "iscejo-dom-footer" },
        {
          url: "/problematika",
          text: "Problematika pointerjev",
          test: "problematika-footer",
        },
        { url: "/O-pasmah", text: "O pasmah", test: "opasmah-footer" },
        { url: "/Posvojitve", text: "Posvojitve", test: "posvojitve-footer" },
        {
          url: "/pogoji-uporabe",
          text: "Pogoji uporabe",
          test: "pogoji-uporabe-footer",
        },
      ],
    };
  },
  methods: {
    scrollBehavior() {
      document.getElementById("app").scrollIntoView();
    },
  },
};
</script>
