<template>
  <div
    class="font-['Times-New-Roman'] overflow-hidden text-zinc-100 bg-teal-900"
  >
    <Cookies :position="bottom" />
    <navigation />

    <router-view />
    <Footer />
  </div>
</template>

<script>
import Navigation from "./components/Navigation/Navigation.vue";
import Footer from "@/components/Footer/Footer.vue";
import Cookies from "./components/SharedComponents/Cookies.vue";
export default {
  name: "App",
  components: {
    Navigation,
    Footer,
    Cookies,
  },
};
</script>
