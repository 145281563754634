<template>
  <footer class="mx-auto w-full">
    <hr class="border-teal-700 mt-80" />
    <div
      class="flex flex-col mt-8 mx-auto items-center justify-between w-10/12 max-w-7xl lg:flex-row lg:mt-0"
    >
      <FirstFooter />
      <InformacijeFooter />
      <Kontakt />
    </div>
    <hr class="border-teal-700" />
    <div class="w-full text-center mt-4 mb-4">
      <p class="text-teal-700 font-light text-sm w-auto">
        Copyright 2023 SASIN - Slovensko društvo za pomoč hrtom in pointerjem
      </p>
    </div>
  </footer>
</template>

<script>
import FirstFooter from "./firstFooter.vue";
import InformacijeFooter from "@/components/Footer/InformacijeFooter.vue";
import Kontakt from "./Kontakt.vue";
export default {
  name: "Footer",
  components: {
    FirstFooter,
    InformacijeFooter,
    Kontakt,
  },
};
</script>
<style scoped>
hr {
  @apply h-1 border-2;
}
</style>
