<template>
  <div class="xl:flex xl:flex-col lg:p-4 space-y-2">
    <div>
      <img
        src="@/images/icon.jpg"
        alt="logo"
        class="w-28"
        data-test="icon-test"
      />
    </div>
    <p class="text-sm mt-3 xl:text-2xl" data-test="display-text-test">
      Slovensko društvo za pomoč hrtom in pointerjem
    </p>
    <div
      class="flex flex-row content-center space-x-8 text-teal-500 hover:underline hover:font-semibold mt-8"
    >
      <p>
        <a
          href="https://www.facebook.com/Pomo%C4%8D-pointerjem-142242529282915/"
          target="_blank"
          class="ml-2 xl:text-2xl"
          data-test="facebook-link"
          ><img
            src="@/assets/Icons/facebook.png"
            alt="facebook"
            class="w-5 h-5"
            data-test="small-icon-test"
        /></a>
      </p>

      <p>
        <a
          href="https://www.instagram.com/pomoc.hrtom/"
          target="_blank"
          class="ml-2 xl:text-2xl"
          data-test="instagram-link"
        >
          <img
            src="@/assets/Icons/instagram.png"
            alt="instagram"
            class="w-5 h-5"
            data-test="small-icon-test"
        /></a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "FirstFooter",
};
</script>
