import { createRouter, createWebHistory } from "vue-router";
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: () => import("../routes/Home.vue"),
    },
    {
      path: "/O-pasmah",
      component: () => import("../routes/AboutBreeds.vue"),
    },
    {
      path: "/O-nas",
      component: () => import("../routes/AboutUs.vue"),
    },
    {
      path: "/Posvojitve",
      component: () => import("../routes/Adoptions.vue"),
    },
    {
      path: "/Kontakt",
      component: () => import("../routes/ContactUs.vue"),
    },
    {
      path: "/iscejo-dom",
      component: () => import("../routes/LookingForHome.vue"),
    },
    {
      path: "/problematika",
      component: () => import("../routes/Problems.vue"),
    },
    {
      path: "/angleski-pointer",
      component: () => import("../routes/EnglishPointer.vue"),
    },
    {
      path: "/nemski-kratkodlaki-pticar",
      component: () => import("../routes/GSP.vue"),
    },
    {
      path: "/pogoji-uporabe",
      component: () => import("../routes/PogojiUporabe.vue"),
    },
    {
      path: "/postopek-posvojitve",
      component: () => import("../routes/KakoPosvojiti.vue"),
    },
    {
      path: "/pripravljeni-na-posvojitev",
      component: () => import("../routes/PripravljeniNaPosvojitev"),
    },
    {
      path: "/nasveti-za-posvojitelje",
      component: () => import("../routes/NasvetiZaPosvojitelje"),
    },
    {
      path: "/pointerji-in-macke",
      component: () => import("../routes/PointerjiInMacke"),
    },
    {
      path: "/pointerji-in-otroci",
      component: () => import("../routes/PointerjiInOtroci"),
    },
    {
      path: "/kako-vzgajati-pointerja",
      component: () => import("../routes/KakoVzgajatoPointerja"),
    },
  ],
});

export default router;
