import { createApp } from "vue";

import App from "./App.vue";
import router from "./router/router.js";
import { initializeApp } from "firebase/app";
//import { getDatabase, ref, onValue } from "firebase/database";
import "./index.css";
import { library } from "@fortawesome/fontawesome-svg-core";
//import { VueCookies } from "vue3-cookies/dist/interfaces";
import VueAnalytics from "vue-analytics";
import Vue from "vue";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faPhone);

const firebaseConfig = {
  apiKey: "AIzaSyA17hX9uHt3HvhXPXbF2FC0hD3AlQ-yI7Q",
  authDomain: "pomoc-pointerjem.firebaseapp.com",
  projectId: "pomoc-pointerjem",
  storageBucket: "pomoc-pointerjem.appspot.com",
  messagingSenderId: "391395428565",
  appId: "1:391395428565:web:2df9641ed9fce8c1cdfa5c",
  measurementId: "G-8QFL39CYMP",
  databaseURL:
    "https://pomoc-pointerjem-default-rtdb.europe-west1.firebasedatabase.app/",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

//const database = getDatabase(app);

const getGDRP = localStorage.getItem("GDRP:accepted");
if (typeof getGDRP !== "undefined" && getGDRP === true) {
  Vue.use(VueAnalytics, {
    id: "G-8QFL39CYMP",
    disabeled: false,
    debug: {
      enabeled: true,
      trace: true,
      sendHitTask: true,
    },
  });
} else if (typeof getGDRP !== "undefined" && getGDRP === false) {
  Vue.use(VueAnalytics, {
    id: "G-8QFL39CYMP",
    disabeled: true,
    debug: {
      enabeled: true,
      trace: true,
      sendHitTask: true,
    },
  });
}

createApp(App).use(router).mount("#app");
