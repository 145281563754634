<template>
  <div class="items-center h-full flex flex-row">
    <img src="@/images/phone-call.png" alt="phone icon" class="w-3 sm:w-4" />
    <p data-test="phone-number-test" class="ml-1 mt-1 xl:text-lg sm:ml-3">
      +386 31 507 305
    </p>

    <div
      v-for="social in socials"
      :key="social"
      class="items-center flex flex-row space-x-8 ml-2"
    >
      <a
        :href="social.url"
        target="_blank"
        :data-test="social.test"
        role="link"
      >
        <img
          :src="social.image"
          class="w-4 sm:w-5"
          :alt="social.alt"
          role="link"
        />
      </a>
    </div>
  </div>
</template>

<script>
import facebook from "@/assets/Icons/facebook.png";
import instagram from "@/assets/Icons/instagram.png";
export default {
  name: "NavContact",
  data() {
    return {
      socials: [
        {
          image: facebook,
          url: "https://www.facebook.com/Pomo%C4%8D-pointerjem-142242529282915/",
          alt: "facebook",
          test: "facebook-test",
        },
        {
          image: instagram,
          url: "https://www.instagram.com/pomoc.hrtom/",
          alt: "instagram",
          test: "instagram-test",
        },
      ],
    };
  },
};
</script>

<style></style>
